/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

import { HeatParser210 } from './HeatParser210';

export class HeatParser220 extends HeatParser210 {
  parseChannelSegmentA(data) {
    const activeTimeA = data[0];
    const statusA = data[1];
    const voltageA = data[2];
    const resistanceA = data[3];
    const temperatureA = (data[4] | (data[5] << 8)) >>> 0;
    const temperatureSettingA = data[6];

    const activeTimeB = data[7];
    const statusB = data[8];
    const voltageB = data[9];
    const resistanceB = data[10];
    const temperatureB = (data[11] | (data[12] << 8)) >>> 0;
    const temperatureSettingB = data[13];

    return {
      channelA: {
        activeTime: activeTimeA,
        status: statusA,
        voltage: voltageA,
        resistance: resistanceA,
        temperature: temperatureA,
        temperatureSetting: temperatureSettingA,
      },
      channelB: {
        activeTime: activeTimeB,
        status: statusB,
        voltage: voltageB,
        resistance: resistanceB,
        temperature: temperatureB,
        temperatureSetting: temperatureSettingB,
      },
    };
  }

  parseChannelSegmentB(data) {
    const activeTimeC = data[0];
    const statusC = data[1];
    const voltageC = data[2];
    const resistanceC = data[3];
    const temperatureC = (data[4] | (data[5] << 8)) >>> 0;
    const temperatureSettingC = data[6];

    const activeTimeD = data[7];
    const statusD = data[8];
    const voltageD = data[9];
    const resistanceD = data[10];
    const temperatureD = (data[11] | (data[12] << 8)) >>> 0;
    const temperatureSettingD = data[13];

    return {
      channelC: {
        activeTime: activeTimeC,
        status: statusC,
        voltage: voltageC,
        resistance: resistanceC,
        temperature: temperatureC,
        temperatureSetting: temperatureSettingC,
      },
      frontChannel: {
        activeTime: activeTimeD,
        status: statusD,
        voltage: voltageD,
        resistance: resistanceD,
        temperature: temperatureD,
        temperatureSetting: temperatureSettingD,
      },
    };
  }

  parseChannelSegmentC(data) {
    const activeTimeE = data[0];
    const statusE = data[1];
    const voltageE = data[2];
    const resistanceE = data[3];
    const temperatureE = (data[4] | (data[5] << 8)) >>> 0;
    const temperatureSettingE = data[6];

    return {
      backChannel: {
        activeTime: activeTimeE,
        status: statusE,
        voltage: voltageE,
        resistance: resistanceE,
        temperature: temperatureE,
        temperatureSetting: temperatureSettingE,
      },
    };
  }
}
