/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

import { HeatParser } from './HeatParser';

export class HeatParser210 extends HeatParser {
  parseInformationCharacteristic(data) {
    const baseCharacteristic = super.parseInformationCharacteristic(data);
    const boardTemperature = data[16] | (data[17] << 8);
    return { boardTemperature, ...baseCharacteristic };
  }
}
