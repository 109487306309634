/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

import { HeatParser400 } from './HeatParser400';

export class HeatParser411 extends HeatParser400 {
  parseInformationCharacteristic(data) {
    const baseCharacteristic = super.parseInformationCharacteristic(data);
    const usedChannelA = Boolean(data[19] & 1);
    const usedChannelB = Boolean((data[19] >> 1) & 1);
    const usedChannelC = Boolean((data[19] >> 2) & 1);
    const usedChannelD = Boolean((data[19] >> 3) & 1);
    const usedChannelE = Boolean((data[19] >> 4) & 1);

    return {
      channelUsedStatus: {
        usedChannelA,
        usedChannelB,
        usedChannelC,
        usedChannelD,
        usedChannelE,
      },
      ...baseCharacteristic,
    };
  }
}
