/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

export class HeatParser {
  parseInformationCharacteristic(data) {
    const hardwareVersion = data[0] >>> 0;
    const firmwareVersion = {
      major: data[1] >>> 0,
      minor: data[2] >>> 0,
      patch: data[3] >>> 0,
      build: data[4] >>> 0,
    };
    const garmentID = data[5] >>> 0;
    const batteryLevel = data[6] >>> 0;
    const heatingRange = data[7] >>> 0;
    const batteryVoltageOptions = [
      data[8] >>> 0,
      data[9] >>> 0,
      data[10] >>> 0,
      data[11] >>> 0,
      data[12] >>> 0,
      data[13] >>> 0,
      data[14] >>> 0,
    ];
    const noMotionaStatus = Boolean(data[15] >>> 0);

    return {
      firmwareVersion,
      hardwareVersion,
      garmentID,
      batteryLevel,
      heatingRange,
      batteryVoltageOptions,
      noMotionaStatus,
    };
  }

  parseChannelSegmentA(data) {
    const activeTimeA = data[0];
    const statusA = data[1];
    const voltageA = data[2];
    const resistanceA = data[3];
    const temperatureA = (data[4] | (data[5] << 8)) >>> 0;

    const activeTimeB = data[6];
    const statusB = data[7];
    const voltageB = data[8];
    const resistanceB = data[9];
    const temperatureB = (data[10] | (data[11] << 8)) >>> 0;

    const activeTimeC = data[12];
    const statusC = data[13];
    const voltageC = data[14];
    const resistanceC = data[15];
    const temperatureC = (data[16] | (data[17] << 8)) >>> 0;
    return {
      channelA: {
        activeTime: activeTimeA,
        status: statusA,
        voltage: voltageA,
        resistance: resistanceA,
        temperature: temperatureA,
      },
      frontChannel: {
        activeTime: activeTimeB,
        status: statusB,
        voltage: voltageB,
        resistance: resistanceB,
        temperature: temperatureB,
      },
      channelC: {
        activeTime: activeTimeC,
        status: statusC,
        voltage: voltageC,
        resistance: resistanceC,
        temperature: temperatureC,
      },
    };
  }

  parseChannelSegmentB(data) {
    const activeTimeD = data[0];
    const statusD = data[1];
    const voltageD = data[2];
    const resistanceD = data[3];
    const temperatureD = (data[4] | (data[5] << 8)) >>> 0;

    const activeTimeE = data[6];
    const statusE = data[7];
    const voltageE = data[8];
    const resistanceE = data[9];
    const temperatureE = (data[10] | (data[11] << 8)) >>> 0;
    return {
      backChannel: {
        activeTime: activeTimeD,
        status: statusD,
        voltage: voltageD,
        resistance: resistanceD,
        temperature: temperatureD,
      },
      channelE: {
        activeTime: activeTimeE,
        status: statusE,
        voltage: voltageE,
        resistance: resistanceE,
        temperature: temperatureE,
      },
    };
  }
}
