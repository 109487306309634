/* eslint-disable no-nested-ternary */
const environment = {
  mmhg_sandbox: {
    awsconfig: {
      aws_project_region: 'ca-central-1',
      aws_cognito_region: 'ca-central-1',
      aws_user_pools_id: 'ca-central-1_uBj0yFC7Z',
      aws_user_pools_web_client_id: '7tlisvli2mt7p5gvtnmnomheaa',
      oauth: {},
    },
    nodeAPI: 'https://api-web.mhg.skiinserver.com/api',
    webSocketLink: 'wss://hiw1gynde6.execute-api.ca-central-1.amazonaws.com/mhg',
    enablePendo: true,
  },
  prod: {
    awsconfig: {
      aws_project_region: 'ca-central-1',
      // aws_cognito_identity_pool_id: 'ca-central-1:6caff98b-cd88-4792-b199-161e5deabbd1',
      aws_cognito_region: 'ca-central-1',
      aws_user_pools_id: 'ca-central-1_72AvhBx7V',
      aws_user_pools_web_client_id: '314ca2pa0o0f7lk5a3nkoga45j',
      oauth: {},
    },
    nodeAPI: 'https://api-web.skiinserver.com/api',
    webSocketLink: 'wss://prod-websocket.skiinserver.com',
    enablePendo: true,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#Prod-SymptomLog',
      symptomAnalysis: 'DynamoDB#Prod-SymptomAnalysis',
    },
  },
  dev: {
    awsconfig: {
      aws_project_region: 'us-east-1',
      // aws_cognito_identity_pool_id: 'us-east-1:793672161147:userpool/us-east-1_bHvuIv5IZ',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_bHvuIv5IZ',
      aws_user_pools_web_client_id: '5gesh7lup6jopc33qfq00up8pi',
      oauth: {},
    },
    nodeAPI: 'https://api-web.dev.skiinserver.com/api',
    webSocketLink: 'wss://lxwkunc4vj.execute-api.us-east-1.amazonaws.com/dev',
    enablePendo: false,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  stag: {
    awsconfig: {
      aws_project_region: 'us-east-2',
      aws_cognito_region: 'us-east-2',
      aws_user_pools_id: 'us-east-2_OTiEEMId8',
      aws_user_pools_web_client_id: '6scnfqt542jioueldthut5g6q4',
      oauth: {},
    },
    nodeAPI: 'https://api-web.stag.skiinserver.com/api',
    webSocketLink: 'wss://ruwq31rxjb.execute-api.us-east-2.amazonaws.com/dev',
    enablePendo: false,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  cnc: {
    awsconfig: {
      aws_project_region: 'ca-central-1',
      aws_cognito_region: 'ca-central-1',
      aws_user_pools_id: 'ca-central-1_QnuzZXgpg',
      aws_user_pools_web_client_id: '313q97m0v7tc89nrd9jbgknobb',
      oauth: {},
    },
    nodeAPI: 'https://api-web.cnc.skiinserver.com/api',
    webSocketLink: 'wss://novfowyvrd.execute-api.ca-central-1.amazonaws.com/cnc',
    enablePendo: false,
    dynamoDBResources: {
      symptomLog: 'DynamoDB#SymptomLog',
      symptomAnalysis: 'DynamoDB#SymptomAnalysis',
    },
  },
  variables: {
    webSocketDataTypes: {
      heartRate: 'heartRate',
      rhr: 'rhr',
      ecgOne: 'ecgOne',
      ecgTwo: 'ecgTwo',
      ecgThree: 'ecgThree',
    },
    webSocketCategoris: {
      heartHealth: 'heartHealth',
    },
    HeartRate: 'HeartRate',
    Activity: 'Activity',
    Steps: 'Steps',
    Temperature: 'Temperature',
    EMPTY_METRICS_VALUE: '--',
    API_CHUNK_SIZE: 25,
    miliSecondsInADay: 86400000,
    nexJURL: 'https://app2.connectedwellness.com/ui/login/main?goto=%252F&org=pace.pace',
  },
};

// eslint-disable-next-line no-nested-ternary
let currentENV = 'dev';

if (window.location.hostname.includes('scwp.skiinserver.com')) currentENV = 'prod';
if (window.location.hostname.includes('scwp.stag.skiinserver.com')) currentENV = 'stag';
if (window.location.hostname.includes('scwp.cnc.skiinserver.com')) currentENV = 'cnc';
if (window.location.hostname.includes('scwp.mhg.skiinserver.com')) currentENV = 'mhg';

export const config = environment[currentENV];

export const currentEnvironment = currentENV;

export const { variables } = environment;

// Parser constants
export const HIGH_BYTE_FACTOR = 4294967296; // 2 ** 32
/// //////////// BLE command codes
export const COMMAND_OPCODES = {
  RTC_RESET_OPCODE: [0x02, 0x00],
  STEPS_RESET_OPCODE: [0x09, 0x00],
  STEPS_THRESHOLD_OPCODE: [0x08, 0x00],
  SET_GARMENT_ID_OPCODE: [0x01, 0x91],
  SET_POD_NAME_OPCODE: [0x00, 0x91],
  SET_SERIAL_NUMBER_OPCODE: [0x01, 0x99],
  GET_SERIAL_NUMBER_OPCODE: [0x00, 0x99],
  IDENTIFY_POD_OPCODE: [0x11, 0x00],
  GET_SECRET_NUMBER_OPCODE: [0x04, 0x99],
  UNLOCK_POD_OPCODE: [0x94, 0xed],
  READ_POD_IV_OPCODE: [0x90, 0xed],
  READ_STORED_DATA_OPCODE: [0x00, 0x90],
  STOP_STORAGE_READ_OPCODE: [0x01, 0x90],
  ERASE_STORED_DATA_OPCODE: [0x02, 0x90],
  ENABLE_AND_DISABLE_STORAGE_OPCODE: [0x10, 0x90],
  SET_LEAD_SETTLING_TIME_OPCODE: [0x02, 0x91],
  GET_LEAD_SETTLING_TIME_OPCODE: [0x03, 0x91],
  ENABLE_AND_DISABLE_LEAD_DETECTION_OPCODE: [0x13, 0x00],
  KEEP_ALIVE: [0x14, 0x00],
};
/// //////////// BLE characteristics
export const SKIIN_SERVICE_UUID = '20046800-9630-4ea8-9d21-04148452e81f';
// Heat
export const HEAT_SERVICE_UUID = '20046200-9630-4ea8-9d21-04148452e81f';
export const STORED_METRICS = {
  STEPS: 0,
  STEPS_DATA_SIZE: 13,
  ACTIVITY: 1,
  ACTIVITY_DATA_SIZE: 6,
  ACTIVITY_DATA_SIZE_LARGE: 10,
  TEMPERATURE: 2,
  TEMPERATURE_DATA_SIZE: 11,
  ECG_CH1: 3,
  ECG_CH1_DATA_SIZE: 33,
  ECG_CH2: 4,
  ECG_CH2_DATA_SIZE: 33,
  ECG_CH3: 5,
  ECG_CH3_DATA_SIZE: 33,
  METRICS: 100,
  ECG: 101,
};

export const COOKIE = {
  GROUP_NAME: 'grpName',
  GROUP_ID: 'grpId',
  GROUP_INFO_TTL: '365', // number of days
};

const aBS = require('./a_bs.json');
const bBS = require('./b_bs.json');
const aLP = require('./a_lp.json');
const bLP = require('./b_lp.json');
const aHP = require('./a_hp.json');
const bHP = require('./b_hp.json');
const aRPeakHP = require('./a_rpHP.json');
const bRPeakHP = require('./b_rpHP.json');

export const filterCoefficients = {
  lowPass: {
    a: aLP,
    b: bLP,
  },
  highPass: {
    a: aHP,
    b: bHP,
  },
  bandStop: {
    a: aBS,
    b: bBS,
  },
  rPeakHP: {
    a: aRPeakHP,
    b: bRPeakHP,
  },
};

export const hrThresholds = {
  desired: 75,
  acceptable: 50,
};

export const hrRanges = {
  low: 30,
  high: 200,
};

export const ecgPadLengths = {
  prePad: 3200,
  postPad: 3200,
};

export const ecgSamplingRate = 320;

export const roles = {
  CARDIAC_TECH: 'CardiacTech',
  PATIENT: 'patient',
  MD: 'MD',
  WELLNESS_COACH: 'Wellness Coach',
  DESK_ADMIN: 'DeskAdmin',
  ADMIN: 'admin',
};

export const SYMPTOM_STATUS = {
  NEW: 'New',
  UPDATED_BY_PATIENT: 'Updated By Patient',
  MD_TO_REVIEW: 'MD To Review',
  REVIEWED_BY_MD: 'Reviewed By MD',
  REVIEWED_BY_TECH: 'Reviewed By Tech',
};

export const eventLogsFilterStatusMapping = {
  'Pending review': ['New', 'Updated By Patient'],
  'MD to review': 'MD To Review',
  'Reviewed by tech': 'Reviewed By Tech',
  'Reviewed by MD': 'Reviewed By MD',
};

export const eventLogsFilterStatusMappingForPatient = {
  'To be reviewed': ['New', 'Updated By Patient', 'MD To Review'],
  Reviewed: ['Reviewed By Tech', 'Reviewed By MD'],
};

export const statusBtnColors = {
  'pending review': {
    backgroundColor: '#1E88E5',
    color: '#FFF',
    borderColor: '#1E88E5',
  },
  'md to review': {
    backgroundColor: '#004C96',
    color: '#FFF',
    borderColor: '#004C96',
  },
  'reviewed by tech': {
    backgroundColor: '#FFF',
    color: '#696F75',
    borderColor: '#696F75',
  },
  'reviewed by md': {
    backgroundColor: '#FFF',
    color: '#004C96',
    borderColor: '#004C96',
  },
};

export const glucoseValues = {
  UNKNOWN: '',
  BEFORE_SLEEP: 'Before Sleep',
  AFTER_SLEEP: 'After Sleep',
  BEFORE_BREAKFAST: 'Before Breakfast',
  AFTER_BREAKFAST: 'After Breakfast',
  BEFORE_LUNCH: 'Before Lunch',
  AFTER_LUNCH: 'After Lunch',
  BEFORE_SUPPER: 'Before Supper',
  AFTER_SUPPER: 'After Supper',
  MID_SLEEP: 'Mid Sleep',
  BEFORE_MEAL: 'Before Meal',
  AFTER_MEAL: 'After Meal',
  CASUAL: 'Casual',
  OTHER: '',
};

export const availableAnnotations = [
  { text: '1AVB', abbr: 'Atrioventricular Block 1st degree' },
  { text: '2AVB1', abbr: 'Atrioventricular Block 2nd degree Type I' },
  { text: '2AVB2', abbr: 'Atrioventricular Block 2nd degree Type II' },
  { text: '3AVB', abbr: 'Complete Heart Block' },
  { text: 'AF', abbr: 'Atrial Fibrillation' },
  { text: 'Artifact' },
  { text: 'AFL', abbr: 'Atrial Flutter' },
  { text: 'BBB', abbr: 'Bundle Branch Block' },
  { text: 'Diagnosis' },
  { text: 'EAT', abbr: 'Ectopic Atrial Tachycardia' },
  { text: 'IRD', abbr: 'Isorhythmic Dissociation' },
  { text: 'IVR', abbr: 'Idioventricular Rhythm' },
  { text: 'JR', abbr: 'Junctional Rhythm' },
  { text: 'MAT', abbr: 'Multifocal Atrial Tachycardia' },
  { text: 'No ECG' },
  { text: 'NNS', abbr: 'Supraventricular Trigeminy' },
  { text: 'NNV', abbr: 'Ventricular Trigeminy' },
  { text: 'NS', abbr: 'Supraventricular Bigeminy' },
  { text: 'NV', abbr: 'Ventricular Bigeminy' },
  { text: 'Pause', abbr: 'Pause' },
  { text: 'SA', abbr: 'Sinus Arrhythmia' },
  { text: 'SR', abbr: 'Sinus Rhythm' },
  { text: 'SVT', abbr: 'Supraventricular Tachycardia' },
  { text: 'VFib', abbr: 'Ventricular Fibrillation' },
  { text: 'VT', abbr: 'Ventricular Tachycardia' },
  { text: 'WAP', abbr: 'Wandering Atrial Pacemaker' },
  { text: 'Other', abbr: 'Other' },
];
