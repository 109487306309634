/* eslint no-param-reassign: 0 */
/* eslint default-param-last: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-shadow: 0 */

import moment from 'moment';
import { decodeBase64AndDecompressRawData } from './CompressAndBase64EncodeRawData';
import ParserFactory from '../Parser/Parser';
import { SKIIN_SERVICE_UUID, variables } from './constants';

const reverseEcgPackets = (ecgArray, samplesPerPacket) => {
  const reversed = new Array(ecgArray.length);
  let k = 0;
  for (let i = ecgArray.length - samplesPerPacket; i >= 0; i -= samplesPerPacket) {
    for (let j = 0; j < samplesPerPacket; j += 1) {
      reversed[k] = ecgArray[i + j];
      k += 1;
    }
  }
  return reversed;
};

export const processChannelEcgData = (channelDataArray, startTime, endTime) => {
  let endTimestamp = null;
  let firstPodCounterPreviouslyProcessedFile = null;
  let currentRealTimestamp = endTimestamp;
  const parsedDataReverse = [];
  let parsedData = [];
  const realTimestamps = [];
  const preDataReverse = [];
  let preData = [];
  const postDataReverse = [];
  let postData = [];
  const maxPodCounter = 16777215;
  const ecgSamplesInPacket = 24;
  const ecgPacketDurationMillSec = 75;
  const preDataDuration = 10000;
  const postDataDuration = 10000;
  const preDataLength = Math.round((preDataDuration / ecgPacketDurationMillSec) * ecgSamplesInPacket);
  const posDataLength = Math.round((postDataDuration / ecgPacketDurationMillSec) * ecgSamplesInPacket);
  /* eslint-disable */
  for (const c of channelDataArray) {
    const parserInstance = ParserFactory.getInstance(c.firmware_version, SKIIN_SERVICE_UUID);
    const bytesPerPacket = parserInstance.ECG_BYTE_TOTAL;
    const b64DecodedDataArray = Array.from(decodeBase64AndDecompressRawData(c.ecg_data, false));
    if (endTimestamp === null) {
      endTimestamp = c.timeStamp * 1;
      currentRealTimestamp = endTimestamp;
    }
    for (let i = b64DecodedDataArray.length - bytesPerPacket, j = 0; i >= 0; i -= bytesPerPacket, j++) {
      const rawPacket = b64DecodedDataArray.slice(i, i + bytesPerPacket);
      const parsedPacket = parserInstance.parseEcgData(rawPacket);
      if (j === 0 && firstPodCounterPreviouslyProcessedFile !== null) {
        let currentPodCounter = parsedPacket.timestamp;
        // Only done for the last packet in file
        let firstPodCounterLastFile = firstPodCounterPreviouslyProcessedFile;
        if (firstPodCounterPreviouslyProcessedFile < parsedPacket.timestamp) {
          // This is a wrap around scenario
          firstPodCounterLastFile = firstPodCounterPreviouslyProcessedFile + maxPodCounter;
        }
        if (parsedPacket.ecg.some(item => item === 0) && parsedPacket.timestamp === 0) {
          // We have a last packet in file packet which did not have a counter value. Need to find the pod counter
          // value for this packet by traversing back to the ecg packet which has a valid pod counter
          for (let k = b64DecodedDataArray.length - 2 * bytesPerPacket, l = 0; k >= 0; k -= bytesPerPacket, l += 1) {
            const raw = b64DecodedDataArray.slice(k, k + bytesPerPacket);
            const parsed = parserInstance.parseEcgData(raw);
            if (parsed.timestamp !== 0) {
              currentPodCounter = parsed.timestamp + (l + 1) * ecgPacketDurationMillSec;
              break;
            }
          }
        }
        // We can now calculate the correct number of zero packets between files
        while (Math.round((firstPodCounterLastFile - currentPodCounter) / ecgPacketDurationMillSec) > 1) {
          currentPodCounter += ecgPacketDurationMillSec;
          // Add packets with zeros
          currentRealTimestamp -= ecgPacketDurationMillSec;
          if (currentRealTimestamp >= startTime && currentRealTimestamp <= endTime) {
            for (let k = 0; k < ecgSamplesInPacket; k++) {
              parsedDataReverse.push(0);
            }
            realTimestamps.push(currentRealTimestamp);
          } else if (currentRealTimestamp >= startTime - preDataDuration && currentRealTimestamp < startTime) {
            for (let k = 0; k < ecgSamplesInPacket; k++) {
              preDataReverse.push(0);
            }
          } else if (currentRealTimestamp > endTime && currentRealTimestamp <= endTime + postDataDuration) {
            for (let k = 0; k < ecgSamplesInPacket; k++) {
              postDataReverse.push(0);
            }
          }
        }
      }
      if (currentRealTimestamp >= startTime && currentRealTimestamp <= endTime) {
        for (let k = 0; k < ecgSamplesInPacket; k++) {
          parsedDataReverse.push(parsedPacket.ecg[k]);
        }
        /* eslint-disable */
        realTimestamps.push(currentRealTimestamp);
      } else if (currentRealTimestamp >= startTime - preDataDuration && currentRealTimestamp < startTime) {
        for(let k = 0; k < ecgSamplesInPacket; k++){
          preDataReverse.push(parsedPacket.ecg[k]);
        }
      } else if (currentRealTimestamp > endTime && currentRealTimestamp <= endTime + postDataDuration) {
        for(let k = 0; k < ecgSamplesInPacket; k++){
          postDataReverse.push(parsedPacket.ecg[k]);
        }
      }
      firstPodCounterPreviouslyProcessedFile = parsedPacket.timestamp;
      currentRealTimestamp -= ecgPacketDurationMillSec;
    }
  }
  // Complete timestamps in from and to
  if(realTimestamps.length > 0) {
    let lastTimeStamp = realTimestamps[realTimestamps.length - 1];
    while(lastTimeStamp > startTime + ecgPacketDurationMillSec) {
      lastTimeStamp -= ecgPacketDurationMillSec;
      realTimestamps.push(lastTimeStamp);
      for(let k = 0; k < ecgSamplesInPacket; k++){
        parsedDataReverse.push(0);
      }
    }
    realTimestamps.reverse();
    parsedData = reverseEcgPackets(parsedDataReverse, ecgSamplesInPacket);
    lastTimeStamp = realTimestamps[realTimestamps.length - 1];
    while(lastTimeStamp < endTime - ecgPacketDurationMillSec) {
      lastTimeStamp += ecgPacketDurationMillSec;
      realTimestamps.push(lastTimeStamp);
      for(let k = 0; k < ecgSamplesInPacket; k++){
        parsedData.push(0);
      }
    }
  }
  if(preDataReverse.length) {
    preData = reverseEcgPackets(preDataReverse, ecgSamplesInPacket);
    if(preData.length < preDataLength) {
      preData = [...new Array(preDataLength - preData.length).fill(0), ...preData]
    } else if(preData.length > preDataLength) {
      preData = preData.slice(preData.length - preDataLength)
    }
  } else if(parsedData.length > 0) {
    // We have some data in valid duration and no preData fill it with zeros
    preData = new Array(preDataLength).fill(0);
  }
  if(postDataReverse.length) {
    postData = reverseEcgPackets(postDataReverse, ecgSamplesInPacket);
    if(postData.length < posDataLength) {
      postData = [...postData, ...new Array(posDataLength - postData.length).fill(0),]
    } else if(postData.length > posDataLength) {
      postData = postData.slice(postData.length - posDataLength)
    }
  } else if(parsedData.length > 0) {
    // We have some data in valid duration and no postData fill it with zeros
    postData = new Array(posDataLength).fill(0);
  }
  return { parsedData, preData, postData, realTimestamps };
}

/* eslint-disable */
export const parseECGDataToFilter = (item, startTime, endTime, dataType = 'ecg') => {
  const parserInstance = ParserFactory.getInstance(item.firmware_version, SKIIN_SERVICE_UUID);
  const CHARS = [0, 26656, 26657, 26658];
  let decodedData;
  if (dataType === 'ecg') decodedData = Array.from(decodeBase64AndDecompressRawData(item.ecg_data, false));
  // Pass second parameter false if files don't contain Base64 data (Files downloaded from backend)
  else return;
  let nextFirmwareSampleCounter = 0;
  let currentFirmwareSampleCounter = 0;
  let nextRealTimestamp = item.timeStamp;
  let currentRealTimestamp = item.timeStamp;
  let parsedData = [];
  let realTimestamps = [];
  let preData = [];
  let postData = [];
  const bytesPerPacket = dataType === 'ecg' ? parserInstance.ECG_BYTE_TOTAL : parserInstance.ACC_BYTE_TOTAL;
  const anomalyThreshold = dataType === 'ecg' ? 750 : 4800;
  let numAnomalies = 0;
  for (let i = decodedData.length - bytesPerPacket, j = 0; i >= 0; i -= bytesPerPacket, j++) {
    const rawPacket = decodedData.slice(i, i + bytesPerPacket);
    const parsedPacket =
      dataType === 'ecg' ? parserInstance.parseEcgData(rawPacket) : parserInstance.parseAccelerometerData(rawPacket);
    if (j === 0) {
      nextFirmwareSampleCounter = parsedPacket.timestamp;
      nextRealTimestamp = item.timeStamp;
      currentRealTimestamp = item.timeStamp;
    } else {
      currentFirmwareSampleCounter = parsedPacket.timestamp;
      let diff = nextFirmwareSampleCounter - currentFirmwareSampleCounter;
      if (diff < 0) {
        const counterMaxValue =
          dataType === 'ecg' ? parserInstance.ECG_COUNTER_MAX_VALUE : parserInstance.ACC_COUNTER_MAX_VALUE;
        if (counterMaxValue - currentFirmwareSampleCounter < 500) {
          diff = counterMaxValue - currentFirmwareSampleCounter + nextFirmwareSampleCounter;
        } else {
          diff = Math.abs(diff);
        }
      }
      if (diff > anomalyThreshold) {
        numAnomalies += 1;
        if (numAnomalies > 1) {
          numAnomalies = 0;
          currentRealTimestamp = nextRealTimestamp - diff;
          nextFirmwareSampleCounter = currentFirmwareSampleCounter;
        } else if (dataType === 'ecg') {
          currentRealTimestamp = nextRealTimestamp - 75;
          nextFirmwareSampleCounter -= 75;
        } else {
          currentRealTimestamp = nextRealTimestamp - 480;
          nextFirmwareSampleCounter -= 480;
        }
      } else {
        numAnomalies = 0;
        currentRealTimestamp = nextRealTimestamp - diff;
        nextFirmwareSampleCounter = currentFirmwareSampleCounter;
      }
      nextRealTimestamp = currentRealTimestamp;
    }
    if (dataType === 'ecg') {
      if (currentRealTimestamp >= startTime && currentRealTimestamp <= endTime) {
        parsedData.push({ ...parsedPacket, ...{ currentRealTimestamp } });
        /* eslint-disable */
        realTimestamps.push(parseInt(currentRealTimestamp));
      } else if (currentRealTimestamp >= startTime - 10000 && currentRealTimestamp < startTime) {
        preData.push(parsedPacket);
      } else if (currentRealTimestamp > endTime && currentRealTimestamp <= endTime + 10000) {
        postData.push(parsedPacket);
      }
    }
  }

  preData = preData.reverse().map(data => data.ecg);
  /* eslint-disable */
  preData = [].concat.apply([], preData);

  postData = postData.reverse().map(data => data.ecg);
  /* eslint-disable */
  postData = [].concat.apply([], postData);

  parsedData = parsedData.reverse().map(data => data.ecg);
  /* eslint-disable */
  parsedData = [].concat.apply([], parsedData);

  realTimestamps = realTimestamps.reverse();
  return { parsedData, preData, postData, realTimestamps };
};

// const stringifyAccSamples = (samples)=>{
//   let stringifiedSamples = ""
//   for (let i=0; i< samples.length; i++){
//     stringifiedSamples += samples[i].x + ','
//     stringifiedSamples += samples[i].y + ','
//     stringifiedSamples += samples[i].z + ','
//   }
//   return stringifiedSamples
// }

export const saveToFileIfRequired = (item, startTime, endTime) => {
  const decodedData = Array.from(decodeBase64AndDecompressRawData(item.temperature_raw_data)); // Pass second parameter false if files don't contain Base64 data (Files downloaded from backend)
  const parserInstance = ParserFactory.getInstance(item.firmware_version, SKIIN_SERVICE_UUID);
  let parsedDataStr = '';
  const bytesPerPacket = parserInstance.TEMPERATURE_RAW_BYTE_TOTAL;
  for (let i = decodedData.length - bytesPerPacket, j = 0; i >= 0; i -= bytesPerPacket, j++) {
    const rawPacket = decodedData.slice(i, i + bytesPerPacket);
    const parsedPacket = parserInstance.parseTemperatureData(rawPacket);
    const currentRealTimestamp = parsedPacket.timestamp * 1000;
    if (currentRealTimestamp >= startTime && currentRealTimestamp <= endTime) {
      parsedDataStr += `26660,${parsedPacket.heatFlux},${parsedPacket.temperature},${
        parsedPacket.coreBodyTemperature
      },${parsedPacket.transformedHeatFlux},${parsedPacket.transformedTemperature},${parsedPacket.timestamp * 1000}\n`;
    }
  }
  return parsedDataStr;
};

/**
 * Dynamically sort an array of objects based on the object key
 */
export const dynamicSort = property => {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */

    let result = 0;

    if (a[property] < b[property]) {
      result = -1;
    }

    if (a[property] > b[property]) {
      result = 1;
    }

    return result * sortOrder;
  };
};

export const getFilterTimestamps = filterString => {
  const today12AM = new Date().setHours(0, 0, 0, 0);
  const currentTime = Date.now();
  switch (filterString) {
    case 'Today':
      return {
        timestampFrom: today12AM,
        timestampTo: currentTime,
      };
    case 'Previous Day':
      return {
        timestampFrom: today12AM - variables.miliSecondsInADay,
        timestampTo: currentTime,
      };
    case '2 Days Ago':
      return {
        timestampFrom: today12AM - variables.miliSecondsInADay * 2,
        timestampTo: currentTime,
      };
    case 'Last Week':
      return {
        timestampFrom: today12AM - variables.miliSecondsInADay * 6,
        timestampTo: currentTime,
      };
    default:
      return {
        timestampFrom: today12AM,
        timestampTo: currentTime,
      };
  }
};

export const sortData = (data, key, sort = 1, date = false) => {
  return data.sort((a, b) => {
    let aVal = a[key] || '';
    let bVal = b[key] || '';
    if (typeof (aVal) === "string") {
      aVal = aVal.toLowerCase();
    }
    if (typeof (bVal) === "string") {
      bVal = bVal.toLowerCase();
    }
    if (!date && sort === 1) {
      if (aVal > bVal) {
        return 1;
      }
      if (bVal > aVal) {
        return -1;
      }
      return 0;
    }
    if (!date && sort === -1) {
      if (aVal < bVal) {
        return 1;
      }
      if (bVal < aVal) {
        return -1;
      }
      return 0;
    }
    // convert timestamps value to int if its string before sorting
    if (typeof (aVal) === "string") {
      aVal = parseInt(aVal,10)
    }
    if (typeof (bVal === "string")) {
      bVal = parseInt(bVal,10)
    }
    if (date && sort === 1) {
      if (new Date(aVal) > new Date(bVal)) {
        return 1;
      }
      if (new Date(bVal) > new Date(aVal)) {
        return -1;
      }
      return 0;
    }
    if (date && sort === -1) {
      if (new Date(aVal) < new Date(bVal)) {
        return 1;
      }
      if (new Date(bVal) < new Date(aVal)) {
        return -1;
      }
      return 0;
    }
    return 1;
  });
};

export const getAge = YYYY_MM_DD => {
  // if user doesn't input birthday, return null ?
  if (!YYYY_MM_DD || YYYY_MM_DD.length !== 10) return null;

  const today = new Date();
  const y1 = today.getFullYear().toString().padStart(4, '0');
  const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
  const d1 = today.getDate().toString().padStart(2, '0');

  // const YYYY_MM_DD = moment(timestamp).format('YYYY-MM-DD');
  const y2 = YYYY_MM_DD.substring(0, 4);
  const m2 = YYYY_MM_DD.substring(5, 7);
  const d2 = YYYY_MM_DD.substring(8, 10);

  return  Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str
  }

  return str.slice(0, num) + '...'
}

export const  formatErrorMessage = message => {
    if (message.includes('username')) {
      return message.replace('username', 'email');
    }
    if (message.includes('Username')) {
      return message.replace('Username', 'Email');
    }
    return message;
};

 // parameter should be a moment() object
  export const getTimePeriod = momentTime => {
    const days = Math.floor(momentTime.asDays());
    const hours = Math.floor(momentTime.asHours());
    const mins = Math.floor(momentTime.asMinutes());

    if (days === 1) {
      return '1 day';
    }
    if (days > 1 && days <= 6) {
      return `${days} days`;
    }

    if (hours === 1) {
      return '1 hour';
    }
    if (hours > 1 && hours <= 23) {
      return `${hours} hours`;
    }

    if (mins === 1 || mins === 0) {
      return '1 min';
    }
    if (mins > 1 && mins <= 59) {
      return `${mins} mins`;
    }

    return undefined;
  };

  export const calculateTimeDiff = (timeBefore, timeAfter) => {
    if (!(timeBefore && timeAfter)) return undefined;
    if (typeof(timeBefore) === "string") {
      timeBefore = parseInt(timeBefore, 10);
    }

    if (typeof (timeAfter) === "string") {
      timeAfter = parseInt(timeAfter, 10);
    }

    let diff = null;
    timeBefore = moment(timeBefore);
    timeAfter = moment(timeAfter);

    diff = moment.duration(timeBefore.diff(timeAfter));
    return diff;
  };

  export const getDateBeforeAndAfter = (data, symptomTimestamp) => {
  if (!data.length) return [];

  const nearestLower = data.filter(item => item.timestamp < symptomTimestamp).sort((a, b) => a - b)[0];
  const nearestUpper = data.filter(item => item.timestamp > symptomTimestamp).sort((a, b) => a - b)[0];

  return [nearestLower, nearestUpper];
};

export const weekBeforeTimestamp = timestamp => {
  const before = moment(timestamp).subtract(6, 'd');
  return before.valueOf();
};

export const weekAfterTimestamp = timestamp => {
  const after = moment(timestamp).add(6, 'd');
  return after.valueOf();
};

export const currentWeek = () => {
  const last7thDay = moment().subtract(6, 'd');
  const start = last7thDay.startOf('day').valueOf();
  const end = moment().valueOf();
  return [start, end];
};

export const assertRole = (assertion, roles) => {
  return roles.includes(assertion);
}

export const hmsFromDuration = (duration, secondsVal = true, shortForm = false) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if(shortForm) { 
    if (hrs > 0) {
      ret += hrs + "h " + (((mins!=0 && mins) < 10 && (mins !==0)) ? "0" : "");
    }
    if (mins > 0) {
      ret += mins + "m " + (((secs!=0 && secs < 10) && (secs !== 0)) ? "0" : "");
    }
    if (secs > 0 && secondsVal) {
      ret += secs + "s";
    }
  } else {
    if (hrs > 0) {
      ret += hrs + "hr " + (((mins!=0 && mins) < 10 && (mins !==0)) ? "0" : "");
    }
    if (mins > 0) {
      ret += mins + "min " + (((secs!=0 && secs < 10) && (secs !== 0)) ? "0" : "");
    }
    if (secs > 0 && secondsVal) {
      ret += secs + "sec";
    }
  }
  
  return ret || variables.EMPTY_METRICS_VALUE;
}

export const numberWithCommas = (x) => {
  x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
  return x;
}

export const deepEqual = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};