import { gql } from '@apollo/client';

// Any user (No patient) - findRawData
export const FIND_ECG_DATA_BY_USER_ID_AND_TIMESTAMP_RANGE = gql`
  query FindRawData(
    $users: [ID!]
    $groupId: String
    $type: String!
    $timestampFrom: String!
    $timestampTo: String!
    $count: Int
  ) {
    findRawData(
      users: $users
      groupId: $groupId
      type: $type
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      count: $count
    ) {
      userId
      data {
        ... on EcgData {
          podId
          timestamp
          channelId
          firmwareVersion
          value
          garmentType
          IV
        }
      }
    }
  }
`;

// Patient - findRawData
export const FIND_ECG_DATA_BY_PATIENT = gql`
  query FindRawData($type: String!, $timestampFrom: String!, $timestampTo: String!, $count: Int) {
    findRawData(type: $type, timestampFrom: $timestampFrom, timestampTo: $timestampTo, count: $count) {
      userId
      data {
        ... on EcgData {
          podId
          timestamp
          channelId
          firmwareVersion
          value
          garmentType
          IV
        }
      }
    }
  }
`;

export const GET_LATEST_ECG_METADATA = gql`
  query getLatestEcgMetaData($users: [ID!], $timestampFrom: String!, $timestampTo: String!, $groupId: String!) {
    getLatestEcgMetaData(users: $users, timestampFrom: $timestampFrom, timestampTo: $timestampTo, groupId: $groupId) {
      userId
      data {
        timestamp
      }
    }
  }
`;

export const GET_OWN_PROFILE = gql`
  query GetOwnProfile {
    getOwnProfile {
      firstName
      lastName
      userName
      displayName
      userId
      dateOfBirth
      sex
      preferredHeightUnit
      preferredWeightUnit
    }
  }
`;

export const GET_ASSOCIATED_PODS = gql`
  query GetAssociatedPods {
    getAssociatedPods {
      podId
      name
      garmentType
    }
  }
`;

export const SEARCH_USER_BY_EMAIL = gql`
  query searchUserByEmail($groupId: String!, $email: String!) {
    searchUserByEmail(groupId: $groupId, email: $email) {
      userId
      email
      firstName
      lastName
    }
  }
`;

export const GET_SYMPTOM_LOG = gql`
  query GetSymptomLog($userId: ID!, $groupId: String!, $timestampFrom: String!, $timestampTo: String!) {
    getSymptomLog(userId: $userId, groupId: $groupId, timestampFrom: $timestampFrom, timestampTo: $timestampTo) {
      userId
      symptomId
      timestamp
      symptom
      intensity
      duration
      data
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const GET_METRIC_BY_USER_V1 = gql`
  query GetMetricByUserV1(
    $groupId: String
    $userId: ID!
    $type: String!
    $timestampFrom: String!
    $timestampTo: String!
  ) {
    getMetricByUserV1(
      groupId: $groupId
      userId: $userId
      type: $type
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
    ) {
      value
      timestamp
    }
  }
`;

export const GET_DOCTORS_BY_GROUP_V2 = gql`
  query getDoctorsByGroupV2($groupId: String!) {
    getDoctorsByGroupV2(groupId: $groupId) {
      docUserId
    }
  }
`;

export const GET_PROFILE_BY_USERS_V1 = gql`
  query getProfileByUsersV1($groupId: String, $users: [String!]) {
    getProfileByUsersV1(groupId: $groupId, users: $users) {
      userId
      email
      firstName
      lastName
      displayName
      userName
      QBloginId
      QBuserId
      QBpassword
      timezone
      dateOfBirth
      sex
      height
      weight
      preferredHeightUnit
      preferredWeightUnit
    }
  }
`;

export const GET_BASELINE_SUMMARY = gql`
  query GetBaselineSummary($userId: ID!, $timestampFrom: String!, $timestampTo: String!) {
    getBaselineSummary(userId: $userId, timestampFrom: $timestampFrom, timestampTo: $timestampTo) {
      baselineMaxLongTermRRStatus
    }
  }
`;

export const GET_PATIENT_ID_V2 = gql`
  query getPatientIdV2($userId: String!, $groupId: String!) {
    getPatientIdV2(userId: $userId, groupId: $groupId) {
      userId
      patientId
      patientIdType
      data
      createdAt
      updatedAt
    }
  }
`;

export const GET_DOCTOR_ASSOCIATION_V2 = gql`
  query getDoctorAssociationV2($userId: String!, $groupId: String!) {
    getDoctorAssociationV2(userId: $userId, groupId: $groupId) {
      userId
      docUserId
      group
      createdAt
      updatedAt
    }
  }
`;

export const GET_DOCTORS_BY_USERS_V1 = gql`
  query getDoctorByUsersV2($users: [String]!, $groupId: String!) {
    getDoctorByUsersV2(users: $users, groupId: $groupId) {
      userId
      docUserId
    }
  }
`;

export const GET_SYMPTOM_ANALYSIS = gql`
  query getSymptomAnalysisV1($groupId: String, $symptomId: String!) {
    getSymptomAnalysisV1(groupId: $groupId, symptomId: $symptomId) {
      symptomId
      analysis
      notes
      timestamp
    }
  }
`;

export const GET_AUDIT_V1 = gql`
  query getAuditV1($groupId: String, $id: ID!) {
    getAuditV1(groupId: $groupId, id: $id) {
      id
      createdAt
      operation
      resource
      value
      resource
    }
  }
`;

export const GET_USERS_STATUS_V1 = gql`
  query getUserStatusV1($groupId: String!, $userId: String!) {
    getUserStatusV1(groupId: $groupId, userId: $userId) {
      userId
      groupId
      status
      incorrectAttributes
    }
  }
`;

export const GET_GROUP_MEMBERS_V1 = gql`
  query getOrgMembersV1($groupId: String!) {
    getOrgMembersV1(groupId: $groupId) {
      id
      firstName
      lastName
      email
      role
      createdAt
    }
  }
`;

export const GET_GROUP_DOMAINS_V1 = gql`
  query getOrgDomainsV1($groupId: String!) {
    getOrgDomainsV1(groupId: $groupId) {
      domain
    }
  }
`;

export const GET_PRE_SIGNED_URL_V2 = gql`
  query getPreSignedURLV2($type: String!, $path: String!, $headers: String) {
    getPreSignedURLV2(type: $type, path: $path, headers: $headers) {
      url
    }
  }
`;

export const GET_HOLTER_DATES_V1 = gql`
  query getHolterDatesV1($symptomId: String!) {
    getHolterDatesV1(symptomId: $symptomId) {
      earliestStartDate
      latestStartDate
      latestEndDate
      totalDataTime
    }
  }
`;

export const SEARCH_PATIENTS_V2 = gql`
  query searchPatientsV2($groupId: String, $keyword: String!, $filter: String!, $from: String, $size: String) {
    searchPatientsV2(groupId: $groupId, keyword: $keyword, filter: $filter, from: $from, size: $size) {
      userId
      status
      name
      profileFirstName
      profileLastName
      dob
      number
      version
    }
  }
`;

export const SEARCH_PATIENTS_V3 = gql`
  query searchPatientsV3(
    $groupId: String
    $keyword: String!
    $filter: String!
    $from: String
    $size: String
    $sortBy: String
    $sortOrder: String
  ) {
    searchPatientsV3(
      groupId: $groupId
      keyword: $keyword
      filter: $filter
      from: $from
      size: $size
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      total
      data {
        userId
        status
        name
        profileFirstName
        profileLastName
        dob
        number
        version
        createdAt
      }
    }
  }
`;

export const GET_USER_GROUP_INVITES_V1 = gql`
  query getUserGroupInvitesV1($groupId: String!) {
    getUserGroupInvitesV1(groupId: $groupId) {
      id
      inviteeEmail
      acceptedAt
      createdAt
      role
      roleDescription
      groupId
    }
  }
`;

export const RESEND_USER_GROUP_INVITE_V1 = gql`
  query resendUserGroupInviteV1($groupId: String!, $invitationId: String!) {
    resendUserGroupInviteV1(groupId: $groupId, invitationId: $invitationId) {
      message
    }
  }
`;

export const GET_USER_GROUP_INVITE_INFO_V1 = gql`
  query getUserGroupInviteInfoV1($invitationToken: String!) {
    getUserGroupInviteInfoV1(invitationToken: $invitationToken) {
      id
      inviteeEmail
      acceptedAt
      createdAt
      role
      roleDescription
      groupId
      groupName
    }
  }
`;

export const GET_LATEST_ACTIVITY_BEFORE_TIMESTAMP_V1 = gql`
  query getLatestActivityBeforeTimestampV1($userId: ID!, $groupId: String, $timestamp: String!) {
    getLatestActivityBeforeTimestampV1(userId: $userId, groupId: $groupId, timestamp: $timestamp) {
      podId
      timestamp
      endTimestamp
      type
      value
    }
  }
`;

export const GET_LATEST_TEMPERATURE_BEFORE_TIMESTAMP_V1 = gql`
  query getLatestTemperatureBeforeTimestampV1($userId: ID!, $groupId: String, $timestamp: String!) {
    getLatestTemperatureBeforeTimestampV1(userId: $userId, groupId: $groupId, timestamp: $timestamp) {
      podId
      timestamp
      endTimestamp
      type
      value
    }
  }
`;

export const GET_ROLES_BY_GROUP_V1 = gql`
  query getRolesByGroupV1($groupId: String!) {
    getRolesByGroupV1(groupId: $groupId) {
      groupId
      role
      description
    }
  }
`;

export const GET_PERMISION_BY_ROLE = gql`
  query getPermissionsByRoleV1($groupId: String!, $role: String!) {
    getPermissionsByRoleV1(groupId: $groupId, role: $role) {
      roleName
      resourceName
      permission
      resourceType
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_ROLES_BY_GROUP = gql`
  query getUserRolesByGroupV1($groupId: String!, $userId: String!) {
    getUserRolesByGroupV1(groupId: $groupId, userId: $userId) {
      userId
      groupId
      roleName
    }
  }
`;

export const GET_ECG_STRIPS_METADATA_V1 = gql`
  query getEcgStripsMetadataV1($symptomId: String!, $groupId: String!) {
    getEcgStripsMetadataV1(symptomId: $symptomId, groupId: $groupId) {
      symptomId
      ecgStripStartTime
      path
    }
  }
`;

export const GET_PORTAL_VERSION = gql`
  query getPortalVersion {
    getPortalVersion {
      timestamp
      version
    }
  }
`;

export const GET_MBE_VERSION = gql`
  query getMBEVersion {
    getMBEVersion {
      timestamp
      version
    }
  }
`;

export const GET_CLINIC_CODE_V1 = gql`
  query getClinicCodeV1($groupId: String!) {
    getClinicCodeV1(groupId: $groupId) {
      groupId
      code
    }
  }
`;

export const GET_SYMPTOM_LOG_BY_GROUP_FOR_USERS_V6 = gql`
  query getSymptomLogByGroupForUsersV6(
    $groupId: String!
    $userId: String
    $count: Int!
    $offset: Int!
    $sortBy: String
    $sortOrder: String
    $filterStatuses: [String]
    $filterDates: [TimeInterval!]
    $filterRoutineECG: Boolean
  ) {
    getSymptomLogByGroupForUsersV6(
      groupId: $groupId
      userId: $userId
      count: $count
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      filterStatuses: $filterStatuses
      filterDates: $filterDates
      filterRoutineECG: $filterRoutineECG
    ) {
      total
      data {
        userId
        createdAt
        groupId
        symptomId
        userGroupStatus
        timestamp
        status
        profileFirstName
        profileLastName
        mdFirstName
        mdLastName
        rowNo
        updatedAt
        symptoms
      }
    }
  }
`;

export const GET_SYMPTOM_LOG_FOR_PATIENT_V1 = gql`
  query getSymptomLogForPatientV1(
    $count: Int!
    $offset: Int!
    $sortBy: String
    $sortOrder: String
    $filterStatuses: [String]
    $filterDates: [TimeInterval!]
  ) {
    getSymptomLogForPatientV1(
      count: $count
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      filterStatuses: $filterStatuses
      filterDates: $filterDates
    ) {
      total
      data {
        userId
        createdAt
        symptomId
        timestamp
        symptoms
        status
        profileFirstName
        profileLastName
        updatedAt
      }
    }
  }
`;

export const GET_GROUP_INFO = gql`
  query getGroupInfo($id: String!) {
    getGroupInfo(id: $id) {
      createdAt
      flagIHR
    }
  }
`;

export const GET_ECG_TAGS = gql`
  query getTagV1($symptomId: String!, $groupId: String!) {
    getTagV1(symptomId: $symptomId, groupId: $groupId) {
      symptomId
      tagId
      groupId
      memberId
      userId
      timestamp
      value
    }
  }
`;

export const GET_AUDIT_V2 = gql`
  query getAuditV2($groupId: String!, $resourceId: String!) {
    getAuditV2(groupId: $groupId, resourceId: $resourceId) {
      id
      userId
      createdAt
      operation
      value
      resource
      resourceId
    }
  }
`;

export const GET_ORG_MEMBER_BY_USER_IDS = gql`
  query getOrgMemberByUserIds($groupId: String!, $userIds: [String!]) {
    getOrgMemberByUserIds(groupId: $groupId, userIds: $userIds) {
      id
      groupId
      firstName
      lastName
      role
      email
      createdAt
    }
  }
`;

export const SEARCH_MY_ORG_V1 = gql`
  query searchMyOrgV1($groupId: String!, $keyword: String!, $filter: String!) {
    searchMyOrgV1(groupId: $groupId, keyword: $keyword, filter: $filter) {
      id
      groupId
      firstName
      lastName
      email
      role
      createdAt
    }
  }
`;

export const GET_METRICS_V2 = gql`
  query getMetricV2(
    $userId: String!
    $groupId: String
    $type: String!
    $timestamp: String!
    $timestampTo: String
    $count: String
    $sort: String
  ) {
    getMetricV2(
      userId: $userId
      groupId: $groupId
      type: $type
      timestamp: $timestamp
      timestampTo: $timestampTo
      count: $count
      sort: $sort
    ) {
      message
      data {
        userId
        uuid
        timestamp
        type
        value
      }
    }
  }
`;

export const GET_AFIB_V1 = gql`
  query getAfibV1($groupId: String!, $userId: String!) {
    getAfibV1(groupId: $groupId, userId: $userId) {
      data {
        userId
        symptomId
        uuid
        timestamp
        type
        value
        createdAt
      }
      message
    }
  }
`;

export const GET_BASELINE_SUMMARY_V3 = gql`
  query GetBaselineSummaryV3($userId: String!, $groupId: String!, $timestamp: String!, $timestampTo: String!) {
    getBaselineSummaryV3(userId: $userId, groupId: $groupId, timestamp: $timestamp, timestampTo: $timestampTo) {
      userId
      sleepDate
      date
      sleepStartedAt
      sleepDeterminedStart
      sleepDeterminedEnd
      durationDataUploaded
      rhr
      baselineRhr
      baselineMinLongTermRhr
      baselineMaxLongTermRhr
      cbt
      baselineCbt
      baselineMinLongTermCbt
      baselineMaxLongTermCbt
      hrv
      baselineHrv
      baselineMinLongTermHrv
      baselineMaxLongTermHrv
      hrvChannel1
      hrvChannel2
      hrvChannel3
      baselineHrvChannel1
      baselineHrvChannel2
      baselineHrvChannel3
      rr
      baselineRR
      baselineMinLongTermRR
      baselineMaxLongTermRR
      sleepScore
      baselineSleepScore
      baselineMinLongTermSleepScore
      baselineMaxLongTermSleepScore
      rhrStatus
      baselineRhrStatus
      baselineMinLongTermRhrStatus
      baselineMaxLongTermRhrStatus
      cbtStatus
      baselineCbtStatus
      baselineMinLongTermCbtStatus
      baselineMaxLongTermCbtStatus
      hrvStatus
      baselineHrvStatus
      baselineMinLongTermHrvStatus
      baselineMaxLongTermHrvStatus
      rrStatus
      baselineRRStatus
      baselineMinLongTermRRStatus
      baselineMaxLongTermRRStatus
      sleepScoreStatus
      baselineSleepScoreStatus
      baselineMinLongTermSleepScoreStatus
      baselineMaxLongTermSleepScoreStatus
      sleepDurationStatus
      baselineSleepDurationStatus
      baselineMinLongTermSleepDurationStatus
      baselineMaxLongTermSleepDurationStatus
      awake
      light
      deep
      rem
      nrem
      sleepDuration
      baselineSleepDuration
      baselineMinLongTermSleepDuration
      baselineMaxLongTermSleepDuration
      sleepQuality
      awakeBeforeSleep
      tossAndTurn
      sleepTimeBack
      sleepTimeLeft
      sleepTimeRight
      sleepTimeFront
      createdAt
      sleepIntervals {
        start
        end
      }
      longTsFrom
      longTsTo
      sleepStages {
        timestamp
        sleepStage
      }
    }
  }
`;

export const GET_BASELINE_ANALYTICS = gql`
  query getBaselineAnalytics(
    $userId: String!
    $groupId: String!
    $timestampFrom: Float!
    $timestampTo: Float!
    $aggregation: String
    $startOfWeek: Int
    $timezone: Int
  ) {
    getBaselineAnalytics(
      userId: $userId
      groupId: $groupId
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      aggregation: $aggregation
      startOfWeek: $startOfWeek
      timezone: $timezone
    ) {
      userId
      timestampFrom
      timestampTo
      aggregation
      startOfWeek
      timezone
      aggr {
        rhr {
          min
          avg
          avgDay
          avgWeek
          avgMonth
          max
          count
        }
        hrv {
          min
          avg
          avgDay
          avgWeek
          avgMonth
          max
          count
        }
        rr {
          min
          avg
          avgDay
          avgWeek
          avgMonth
          max
          count
        }
        cbt {
          min
          avg
          avgDay
          avgWeek
          avgMonth
          max
          count
        }
        sd {
          min
          avg
          avgDay
          avgWeek
          avgMonth
          max
          count
        }
      }
      chart {
        timestampFrom
        timestampTo
        rhr {
          day
          week
          month
          baseline
          min
          max
        }
        hrv {
          day
          week
          month
          baseline
          min
          max
        }
        rr {
          day
          week
          month
          baseline
          min
          max
        }
        cbt {
          day
          week
          month
          baseline
          min
          max
        }
        sd {
          day
          week
          month
          baseline
          min
          max
        }
      }
    }
  }
`;

export const GET_NOTES_V1 = gql`
  query GetNotesV1($userId: String!, $groupId: String!) {
    getNotesV1(userId: $userId, groupId: $groupId) {
      userId
      createdAt
      groupId
      createdBy
      title
      description
    }
  }
`;

export const GET_MEMBER_SETTINGS_V1 = gql`
  query getMemberSettingsV1($groupId: String!, $patientId: String!, $key: String!) {
    getMemberSettingsV1(groupId: $groupId, patientId: $patientId, key: $key) {
      userId
      settingId
      groupId
      patientId
      key
      settings
      timestamp
    }
  }
`;

export const GET_TAG_V1 = gql`
  query GetTagV1($symptomId: String!, $groupId: String!) {
    getTagV1(symptomId: $symptomId, groupId: $groupId) {
      symptomId
      tagId
      groupId
      memberId
      userId
      timestamp
      value
    }
  }
`;

export const GET_DAILY_SUMMARY_V1 = gql`
  query GetDailySummaryV1(
    $timestampFrom: String!
    $timestampTo: String!
    $type: String!
    $userId: String
    $groupId: String
  ) {
    getDailySummaryV1(
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      type: $type
      userId: $userId
      groupId: $groupId
    ) {
      userId
      timestamp
      value
    }
  }
`;

export const GET_METRIC_ANALYTICS = gql`
  query getMetricAnalytics(
    $userId: String!
    $groupId: String!
    $timestampFrom: Float!
    $timestampTo: Float!
    $aggregation: String
    $metric: String
    $startOfWeek: Int
    $timezone: Int
  ) {
    getMetricAnalytics(
      userId: $userId
      groupId: $groupId
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      aggregation: $aggregation
      metric: $metric
      startOfWeek: $startOfWeek
      timezone: $timezone
    ) {
      userId
      timestampFrom
      timestampTo
      aggregation
      startOfWeek
      timezone
      aggr {
        bp {
          systolic {
            min
            avg
            avgSitting
            avgStanding
            avgLyingDown
            avgUnknown
            avgAwake
            avgAsleep
            max
            count
            countSitting
            countStanding
            countLyingDown
            countUnknown
            countAwake
            countAsleep
          }
          diastolic {
            min
            avg
            avgSitting
            avgStanding
            avgLyingDown
            avgUnknown
            avgAwake
            avgAsleep
            max
            count
            countSitting
            countStanding
            countLyingDown
            countUnknown
            countAwake
            countAsleep
          }
          hr {
            min
            avg
            max
            count
          }
        }
        os {
          os {
            min
            avg
            max
            count
          }
          hr {
            min
            avg
            max
            count
          }
        }
        weight {
          weight {
            min
            avg
            max
            count
          }
        }
        glucose {
          glucose {
            min
            avg
            avgUnknown
            avgBeforeSleep
            avgAfterSleep
            avgBeforeBreakfast
            avgAfterBreakfast
            avgBeforeLunch
            avgAfterLunch
            avgBeforeSupper
            avgAfterSupper
            avgMidSleep
            avgBeforeMeal
            avgAfterMeal
            avgCasual
            avgOther
            max
            count
            countUnknown
            countBeforeSleep
            countAfterSleep
            countBeforeBreakfast
            countAfterBreakfast
            countBeforeLunch
            countAfterLunch
            countBeforeSupper
            countAfterSupper
            countMidSleep
            countBeforeMeal
            countAfterMeal
            countCasual
            countOther
          }
        }
        steps {
          steps {
            min
            avg
            avgWeek
            avgMonth
            max
            count
          }
        }
        hr {
          hr {
            min
            avg
            avgAwake
            avgAsleep
            max
            count
            countAwake
            countAsleep
          }
        }
      }
      chart {
        bp {
          timestampFrom
          timestampTo
          systolic {
            day
            baseline
            min
            max
            week
            month
            count
          }
          diastolic {
            day
            baseline
            min
            max
            week
            month
            count
          }
          hr {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
        os {
          timestampFrom
          timestampTo
          os {
            day
            baseline
            min
            max
            week
            month
            count
          }
          hr {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
        weight {
          timestampFrom
          timestampTo
          weight {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
        glucose {
          timestampFrom
          timestampTo
          glucose {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
        steps {
          timestampFrom
          timestampTo
          steps {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
        hr {
          timestampFrom
          timestampTo
          hr {
            day
            baseline
            min
            max
            week
            month
            count
          }
        }
      }
    }
  }
`;

export const GET_HOLTER_DATES_V2 = gql`
  query GetHolterDatesV2($groupId: String!, $symptomId: String!) {
    getHolterDatesV2(groupId: $groupId, symptomId: $symptomId) {
      earliestStartDate
      earliestStartDateDuration
      latestStartDate
      latestStartDateDuration
    }
  }
`;

export const GET_GROUP_V2 = gql`
  query getGroupV2 {
    getGroupV2 {
      id
    }
  }
`;

export const GET_HEART_RATE_ANALYTICS = gql`
  query getHeartRateAnalytics(
    $userId: String!
    $groupId: String!
    $timestampFrom: Float!
    $timestampTo: Float!
    $timezone: Int
  ) {
    getHeartRateAnalytics(
      userId: $userId
      groupId: $groupId
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      timezone: $timezone
    ) {
      userId
      timestampFrom
      timestampTo
      timezone
      aggr {
        hr {
          sleepStartTime
          sleepEndTime
          hr {
            min
            avg
            max
            count
          }
        }
      }
      chart {
        hr {
          timestampFrom
          timestampTo
          hr {
            hour
            min
            max
            count
          }
        }
      }
      source {
        hr {
          timestamp
          hr {
            value
          }
        }
      }
    }
  }
`;

export const GET_ECG_META_HOURS_BY_USER_ID_AND_TIMESTAMP_RANGE = gql`
  query getEcgMetaDataByUserIdAndTimestampRange(
    $timestampFrom: String!
    $timestampTo: String!
    $channelId: String
    $userId: String!
    $groupId: String!
  ) {
    getEcgMetaDataByUserIdAndTimestampRange(
      timestampFrom: $timestampFrom
      timestampTo: $timestampTo
      channelId: $channelId
      userId: $userId
      groupId: $groupId
    ) {
      podId
      timestamp
      channelId
      firmwareVersion
      duration
      garmentType
      IV
    }
  }
`;

export const GET_HEART_RATE_BY_RPEAKS = gql`
  query getHeartRateByRPeaks($timestampFrom: String!, $timestampTo: String!, $userId: ID!, $groupId: String!) {
    getHeartRateByRPeaks(timestampFrom: $timestampFrom, timestampTo: $timestampTo, userId: $userId, groupId: $groupId) {
      podId
      timestamp
      endTimestamp
      type
      value
      error
      code
    }
  }
`;

export const GET_PATIENT_PROFILE = gql`
  query getPatientProfile($userId: String!, $groupId: String!) {
    getPatientProfile(userId: $userId, groupId: $groupId) {
      userId
      groupId
      firstName
      lastName
      dateOfBirth
      sex
      email
      phone
      policy {
        type
        number
        digit
        expiry
      }
      emrId
      medicalRecordNumber
      homeAddress {
        street
        city
        postalCode
        country
      }
      docUserId
    }
  }
`;
export const GET_ALL_STUDIES_BY_USER = gql`
  query getAllStudiesByUser($userId: String!, $groupId: String!) {
    getAllStudiesByUser(userId: $userId, groupId: $groupId) {
      id
      duration
      groupId
      userId
      closedBy
      expectedEndTimestamp
      endTimestamp
      startTimestamp
      aggregatedTotalMinutes
      aggregatedQualityMinutes
      studyType
    }
  }
`;

export const GET_OPEN_STUDIES_BY_CLINIC = gql`
  query getOpenStudiesByClinic($groupId: String!) {
    getOpenStudiesByClinic(groupId: $groupId) {
      id
      duration
      groupId
      userId
      firstName
      lastName
      expectedEndTimestamp
      endTimestamp
      startTimestamp
      aggregatedTotalMinutes
      aggregatedQualityMinutes
      studyType
    }
  }
`;

export const GET_PATIENT_PROFILE_BY_EMR_ID = gql`
  query getPatientProfileByEmrId($emrId: String!) {
    getPatientProfileByEmrId(emrId: $emrId) {
      emrId
      salutation
      firstName
      lastName
      dateOfBirth
      sex
      ohip
      versionCode
      province
      issueDate
      validDate
      expiryDate
      rmb
      active
      sin
      mainDoctor
      familyDoctor
      associatedDoctor
    }
  }
`;

export const GET_ALL_STUDIES_BY_CLINIC = gql`
  query getAllStudiesByClinic($groupId: String!) {
    getAllStudiesByClinic(groupId: $groupId) {
      id
      duration
      groupId
      userId
      firstName
      lastName
      closedBy
      expectedEndTimestamp
      endTimestamp
      startTimestamp
      aggregatedTotalMinutes
      aggregatedQualityMinutes
      studyType
    }
  }
`;

export const GET_USER_LOGIN_INFO = gql`
  query getUserLoginInfo($userId: String!, $groupId: String!) {
    getUserLoginInfo(userId: $userId, groupId: $groupId) {
      userId
      email
      groupId
      token
      oneTimeCode
    }
  }
`;
export const GET_STUDY_READINGS = gql`
  query getStudyReadings($studyId: String!, $groupId: String!) {
    getStudyReadings(studyId: $studyId, groupId: $groupId) {
      id
      createdBy
      studyId
      qualityMinutes
      status
      timestamp
      totalMinutes
    }
  }
`;

export const GET_POD_ASSOCIATED_TO_USERS = gql`
  query getPodsAssociatedToUser($userId: String!, $groupId: String!) {
    getPodsAssociatedToUser(userId: $userId, groupId: $groupId) {
      podId
      startTimestamp
      endTimestamp
      garmentType
      name
    }
  }
`;
