import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import Amplify from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { GridLoader } from 'react-spinners';
import { config } from './Utils/constants';
import { AWS } from './Services/AuthService';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure({
  ...config.awsconfig,
  Analytics: {
    disabled: true,
  },
});

const loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
    }}
  >
    <GridLoader color="#033761" loading size={13} margin={2} />
  </div>
);

// Pages (After Login)
const DefaultLayout = React.lazy(() => import('./Pages/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./Pages/Login/Login'));
const ResetPassword = React.lazy(() => import('./Pages/Login/ResetPassword/ResetPassword'));
const CreatePassword = React.lazy(() => import('./Pages/Login/ResetPassword/CreatePassword'));
const Register = React.lazy(() => import('./Pages/Register/Register'));
const Page404 = React.lazy(() => import('./Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./Pages/Page500/Page500'));
const SignUp = React.lazy(() => import('./Pages/SignUp/Signup'));

class App extends Component {
  constructor(props) {
    super(props);
    this.aws = AWS.getInstance();

    this.state = {
      isAuthenticated: false,
      isLoading: true,
    };
    this.aws.checkUserAuthenticated();
  }

  componentDidMount() {
    this.aws.isAuthenticated.subscribe(result => {
      if (typeof result === 'boolean') {
        this.setState({ isAuthenticated: result, isLoading: false });
      }
    });
  }

  render() {
    const { isAuthenticated, isLoading } = this.state;

    if (isLoading) return loading();
    // if (isAuthenticated && (this.aws.permissions === undefined || !this.aws.permissions.length)) return loading();

    return (
      <ApolloProvider client={this.aws.getClient()}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <ToastContainer />
            {!isAuthenticated ? (
              <Switch>
                <Route
                  exact
                  path="/reset-password"
                  name="Reset Password Page"
                  render={props => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/create-password"
                  name="Create Password Page"
                  render={props => <CreatePassword {...props} />}
                />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route exact path="/signup" name="Sign Up Page" render={props => <SignUp {...props} />} />
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route path="/" name="Login Page" render={props => <Login {...props} />} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
              </Switch>
            )}
          </React.Suspense>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;
