/* eslint import/prefer-default-export: 0 */
/* eslint no-bitwise: 0 */

import { HeatParser220 } from './HeatParser220';

export class HeatParser400 extends HeatParser220 {
  parseInformationCharacteristic(data) {
    const baseCharacteristic = super.parseInformationCharacteristic(data);
    const overheatStatusPCB = Boolean(data[18] & 1);
    const overheatStatusChannelA = Boolean((data[18] >> 1) & 1);
    const overheatStatusFrontChannel = Boolean((data[18] >> 2) & 1);
    const overheatStatusChannelC = Boolean((data[18] >> 3) & 1);
    const overheatStatusBackChannel = Boolean((data[18] >> 4) & 1);
    const overheatStatusChannelE = Boolean((data[18] >> 5) & 1);

    return {
      overheatStatusPCB,
      channelHeatStatus: {
        overheatStatusChannelA,
        overheatStatusFrontChannel,
        overheatStatusChannelC,
        overheatStatusBackChannel,
        overheatStatusChannelE,
      },
      ...baseCharacteristic,
    };
  }
}
