/* eslint no-bitwise: 0 */
/* eslint max-classes-per-file: 0 */

import {
  HIGH_BYTE_FACTOR,
  COMMAND_OPCODES,
  SKIIN_SERVICE_UUID,
  HEAT_SERVICE_UUID,
  STORED_METRICS,
} from '../Utils/constants';
// import log from '../../utils/logManager'
import { HeatParser } from './HeatParsers/HeatParser';
import { HeatParser210 } from './HeatParsers/HeatParser210';
import { HeatParser220 } from './HeatParsers/HeatParser220';
import { HeatParser400 } from './HeatParsers/HeatParser400';
import { HeatParser411 } from './HeatParsers/HeatParser411';

export class Parser {
  constructor() {
    this.ECG_SAMPLE_COUNT = 40;
    this.ECG_SAMPLING_FREQ = 320;
    this.ECG_BYTE_COUNT = 3;
    this.ECG_BYTE_TOTAL = 124;
    this.ECG_INTERVAL = 124;
    this.ACC_SAMPLE_COUNT = 18;
    this.ACC_BYTE_COUNT = 2;
    this.DEFAULT_NAME = 'udw_afib';
  }

  parseInformationCharacteristic(data) {
    const firmwareVersion = {
      major: data[0] >>> 0,
      minor: data[1] >>> 0,
      patch: (data[2] | (data[3] << 8)) >>> 0,
    };
    const hardwareVersion = (data[4] | (data[5] << 8)) >>> 0;
    const operationMode = Boolean(data[6] & 1);
    const ecgLeadState = Boolean(data[7] & 1);
    const batteryLevel = data[8] >>> 0;
    const storedEcg = Boolean(data[9]);
    const movementFlag = Boolean(data[10]);
    const ecgOneQuality = data[11] >>> 0;
    const ecgTwoQuality = data[12] >>> 0;
    const connectionInterval = (data[13] | (data[14] << 8)) >>> 0;
    const chargingTime = (data[15] | (data[16] << 8)) >>> 0;
    const temperature = (data[17] | (data[18] << 8)) >>> 0;

    return {
      firmwareVersion,
      hardwareVersion,
      operationMode,
      ecgLeadState,
      batteryLevel,
      storedEcg,
      movementFlag,
      ecgOneQuality,
      ecgTwoQuality,
      connectionInterval,
      chargingTime,
      temperature,
    };
  }

  parseResponseData(data) {
    // log.info('Command response :', data)
    if (
      data[0] === COMMAND_OPCODES.GET_SERIAL_NUMBER_OPCODE[0] &&
      data[1] === COMMAND_OPCODES.GET_SERIAL_NUMBER_OPCODE[1]
    ) {
      const lowBytes = (data[3] | (data[4] << 8) | (data[5] << 16) | (data[6] << 24)) >>> 0;
      const highBytes = (data[7] | (data[8] << 8)) >>> 0;
      const responseData = highBytes * HIGH_BYTE_FACTOR + lowBytes;
      return { data: responseData };
    }
    return { data: null };
  }

  parseEcgData(data) {
    const ecg = new Array((data.length - 4) / this.ECG_BYTE_COUNT);
    for (let i = 0; i < ecg.length; i++) {
      const inc = i * this.ECG_BYTE_COUNT;
      ecg[i] = (data[0 + inc] | (data[1 + inc] << 8) | (data[2 + inc] << 16)) >>> 0;
    }
    const offset = ecg.length * this.ECG_BYTE_COUNT;
    const timestamp = (data[offset] | (data[1 + offset] << 8) | (data[2 + offset] << 16)) >>> 0;
    const leadState = data[3 + offset] & 1;
    const ecgQuality = data[3 + offset] >>> 1;

    return { timestamp, ecg, leadState, ecgQuality, rawData: data };
  }

  parseStepsData(data) {
    const currTime = Date.now();
    const currBytes = Math.floor(currTime / HIGH_BYTE_FACTOR);
    const currHigh = currBytes * HIGH_BYTE_FACTOR;
    const stepsCounter = (data[0] | (data[1] << 8) | (data[2] << 16) | (data[3] << 24)) >>> 0;
    let startTime = (data[4] | (data[5] << 8) | (data[6] << 16) | (data[7] << 24)) >>> 0;
    let endTime = (data[8] | (data[9] << 8) | (data[10] << 16) | (data[11] << 24)) >>> 0;
    startTime = currHigh + startTime;
    endTime = currHigh + endTime;
    return { stepsCounter, startTime, endTime, rawData: data };
  }

  parseActivityData(data) {
    const currTime = Date.now();
    const currBytes = Math.floor(currTime / HIGH_BYTE_FACTOR);
    const currHigh = currBytes * HIGH_BYTE_FACTOR;
    const activityId = data[0] >>> 0;
    let startTime = (data[1] | (data[2] << 8) | (data[3] << 16) | (data[4] << 24)) >>> 0;
    startTime = currHigh + startTime;
    return { activityId, startTime, rawData: data };
  }

  parseAccelerometerData(data, hasThreeByteTimestamp = false) {
    let samples;
    if (hasThreeByteTimestamp) {
      samples = new Array((data.length - 3) / (3 * this.ACC_BYTE_COUNT));
    } else {
      samples = new Array((data.length - 2) / (3 * this.ACC_BYTE_COUNT));
    }
    for (let i = 0; i < samples.length; i++) {
      const inc = i * 3 * this.ACC_BYTE_COUNT;
      samples[i] = {
        x: ((data[0 + inc] | (data[1 + inc] << 8)) << 16) >> 16,
        y: ((data[2 + inc] | (data[3 + inc] << 8)) << 16) >> 16,
        z: ((data[4 + inc] | (data[5 + inc] << 8)) << 16) >> 16,
      };
    }
    const offset = samples.length * 3 * this.ACC_BYTE_COUNT;
    let timestamp;
    if (hasThreeByteTimestamp) timestamp = (data[offset] | (data[1 + offset] << 8) | (data[2 + offset] << 16)) >>> 0;
    else timestamp = (data[offset] | (data[1 + offset] << 8)) >>> 0;

    return { timestamp, samples, rawData: data };
  }
} // end of Parser

class Parser321 extends Parser {
  parseTemperatureData(data) {
    const currTime = Date.now();
    const currBytes = Math.floor(currTime / HIGH_BYTE_FACTOR);
    const currHigh = currBytes * HIGH_BYTE_FACTOR;
    const heatFlux = data[0] | (data[1] << 8);
    const temperature = (data[2] | (data[3] << 8)) >>> 0;
    let timestamp = (data[4] | (data[5] << 8) | (data[6] << 16) | (data[7] << 24)) >>> 0;
    timestamp = currHigh + timestamp;
    return { heatFlux, temperature, timestamp, rawData: data };
  }
} // end of Parser321

class Parser400 extends Parser {
  constructor() {
    super();
    this.ECG_SAMPLE_COUNT = 24;
    this.ECG_INTERVAL = 75;
    this.ACC_SAMPLE_COUNT = 12;
    this.ECG_BYTE_TOTAL = 76;
    this.TEMPERATURE_RAW_BYTE_TOTAL = 10;
    this.ACC_BYTE_TOTAL = 74;
    this.ACC_INTERVAL = 480;
    this.ECG_COUNTER_MAX_VALUE = 16777215;
    this.ACC_COUNTER_MAX_VALUE = 65535;
  }

  parseInformationCharacteristic(data) {
    const firmwareVersion = {
      major: data[0] >>> 0,
      minor: data[1] >>> 0,
      patch: (data[2] | (data[3] << 8)) >>> 0,
    };
    const hardwareVersion = (data[4] | (data[5] << 8)) >>> 0;
    const ecgLeadState = Boolean(data[7] & 1);
    const batteryLevel = data[8] >>> 0;
    const storedData = Boolean(data[9]);
    const metricsSegCount = (data[10] | (data[11] << 8)) >>> 0;
    const ecgSegCount = (data[12] | (data[13] << 8)) >>> 0;
    const chargingState = data[14] >>> 0; // 00 ready(not connected to power); 01 charging; 02 complete(Fully charged); 03 fault(problem charging);
    const connectionInterval = (data[15] | (data[16] << 8)) >>> 0; // * 1.25 = ms
    const temperature = (data[17] | (data[18] << 8)) >>> 0;
    const movementFlag = Boolean(data[19]);
    return {
      firmwareVersion,
      hardwareVersion,
      ecgLeadState,
      batteryLevel,
      storedData,
      metricsSegCount,
      ecgSegCount,
      chargingState,
      connectionInterval,
      temperature,
      movementFlag,
    };
  }

  parseTemperatureData(data) {
    const currTime = Date.now();
    const currBytes = Math.floor(currTime / HIGH_BYTE_FACTOR);
    const currHigh = currBytes * HIGH_BYTE_FACTOR;
    const heatFlux = data[0] | (data[1] << 8);
    const temperature = (data[2] | (data[3] << 8)) >>> 0;
    const coreBodyTemperature = (data[4] | (data[5] << 8)) >>> 0;
    let timestamp = (data[6] | (data[7] << 8) | (data[8] << 16) | (data[9] << 24)) >>> 0;
    timestamp = currHigh + timestamp;
    return { heatFlux, temperature, coreBodyTemperature, timestamp, rawData: data };
  }

  parseEcgData(data) {
    const ecg = new Array((data.length - 4) / this.ECG_BYTE_COUNT);
    for (let i = 0; i < ecg.length; i++) {
      const inc = i * this.ECG_BYTE_COUNT;
      ecg[i] = (data[0 + inc] | (data[1 + inc] << 8) | (data[2 + inc] << 16)) >>> 0;
    }
    const offset = ecg.length * this.ECG_BYTE_COUNT;
    const timestamp = (data[offset] | (data[1 + offset] << 8) | (data[2 + offset] << 16)) >>> 0;
    const leadState = data[3 + offset] & 1;
    const leadStateN = (data[3 + offset] >> 1) & 1;
    const leadStateP = (data[3 + offset] >> 2) & 1;
    const movementFlag = (data[3 + offset] >> 3) & 1;
    const ecgQuality = data[3 + offset] >>> 3;

    return { timestamp, ecg, leadState, leadStateN, leadStateP, ecgQuality, movementFlag, rawData: data };
  }
} // Parser400

class Parser420 extends Parser400 {
  parseInformationCharacteristic(data) {
    const firmwareVersion = {
      major: data[0] >>> 0,
      minor: data[1] >>> 0,
      patch: (data[2] | (data[3] << 8)) >>> 0,
    };
    const hardwareVersion = (data[4] | (data[5] << 8)) >>> 0;
    const garmentID = data[6] >>> 0;
    const ecgLeadState = Boolean(data[7] & 1);
    const batteryLevel = data[8] >>> 0;
    const storedData = Boolean(data[9]);
    const metricsSegCount = (data[10] | (data[11] << 8)) >>> 0;
    const ecgSegCount = (data[12] | (data[13] << 8)) >>> 0;
    const chargingState = data[14] >>> 0; // 00 ready(not connected to power); 01 charging; 02 complete(Fully charged); 03 fault(problem charging);
    const connectionInterval = (data[15] | (data[16] << 8)) >>> 0; // * 1.25 = ms
    const temperature = (data[17] | (data[18] << 8)) >>> 0;
    const movementFlag = Boolean(data[19]);
    return {
      firmwareVersion,
      hardwareVersion,
      ecgLeadState,
      garmentID,
      batteryLevel,
      storedData,
      metricsSegCount,
      ecgSegCount,
      chargingState,
      connectionInterval,
      temperature,
      movementFlag,
    };
  }
} // end of Parser420

class Parser430 extends Parser420 {
  parseTemperatureData(data) {
    const heatFlux = data[0] | (data[1] << 8);
    const temperature = (data[2] | (data[3] << 8)) >>> 0;
    const coreBodyTemperature = (data[4] | (data[5] << 8)) >>> 0;
    const timestamp = (data[6] | (data[7] << 8) | (data[8] << 16) | (data[9] << 24)) >>> 0;
    return { heatFlux, temperature, coreBodyTemperature, timestamp, rawData: data };
  }

  parseStepsData(data) {
    const stepsCounter = (data[0] | (data[1] << 8) | (data[2] << 16) | (data[3] << 24)) >>> 0;
    let startTime = (data[4] | (data[5] << 8) | (data[6] << 16) | (data[7] << 24)) >>> 0;
    startTime *= 1000;
    let endTime = (data[8] | (data[9] << 8) | (data[10] << 16) | (data[11] << 24)) >>> 0;
    endTime *= 1000;
    return { stepsCounter, startTime, endTime, rawData: data };
  }

  parseActivityData(data, hasStepsCounter = false) {
    const activityId = data[0] >>> 0;
    const startTime = (data[1] | (data[2] << 8) | (data[3] << 16) | (data[4] << 24)) >>> 0;
    let stepsCounter = 0;
    if (hasStepsCounter) {
      stepsCounter = (data[5] | (data[6] << 8) | (data[7] << 16) | (data[8] << 24)) >>> 0;
      return { activityId, startTime, rawData: data, stepsCounter };
    }
    return { activityId, startTime, rawData: data };
  }

  parseStoredMetricsData(data, activitiesWithStepCounter = false) {
    if (!data || !(Array.isArray(data) && data.length > 0)) return {};
    let buffer = [...data];
    const stepsData = [];
    const activityData = [];
    const temperatureData = [];
    const ecgCh1Data = [];
    const ecgCh2Data = [];
    const ecgCh3Data = [];
    let storageId;
    let partArray;
    while (buffer.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      storageId = buffer[0];
      switch (storageId) {
        case STORED_METRICS.STEPS:
          partArray = buffer.splice(0, STORED_METRICS.STEPS_DATA_SIZE);
          partArray.shift();
          // stepsData.push(this.parseStepsData(partArray))
          break;
        case STORED_METRICS.ACTIVITY:
          if (activitiesWithStepCounter) {
            const id = buffer[1] >>> 0;
            const activitiesWithSteps = [1, 2, 11, 12];
            if (activitiesWithSteps.includes(id)) partArray = buffer.splice(0, STORED_METRICS.ACTIVITY_DATA_SIZE_LARGE);
            else partArray = buffer.splice(0, STORED_METRICS.ACTIVITY_DATA_SIZE);
          } else {
            partArray = buffer.splice(0, STORED_METRICS.ACTIVITY_DATA_SIZE);
          }
          partArray.shift();
          // eslint-disable-next-line no-case-declarations
          const hasStepsCounter = partArray.length > 5;
          activityData.push(this.parseActivityData(partArray, hasStepsCounter));
          break;
        case STORED_METRICS.TEMPERATURE:
          partArray = buffer.splice(0, STORED_METRICS.TEMPERATURE_DATA_SIZE);
          partArray.shift();
          temperatureData.push(this.parseStoredTemperatureData(partArray));
          break;
        case STORED_METRICS.ECG_CH1:
          partArray = buffer.splice(0, STORED_METRICS.ECG_CH1_DATA_SIZE);
          partArray.shift();
          ecgCh1Data.push(this.parseStoredEcgData(partArray));
          break;
        case STORED_METRICS.ECG_CH2:
          partArray = buffer.splice(0, STORED_METRICS.ECG_CH2_DATA_SIZE);
          partArray.shift();
          ecgCh2Data.push(this.parseStoredEcgData(partArray));
          break;
        case STORED_METRICS.ECG_CH3:
          partArray = buffer.splice(0, STORED_METRICS.ECG_CH3_DATA_SIZE);
          partArray.shift();
          ecgCh3Data.push(this.parseStoredEcgData(partArray));
          break;
        default:
          buffer = [];
          break;
      }
    }
    return { stepsData, activityData, temperatureData, ecgCh1Data, ecgCh2Data, ecgCh3Data, rawData: data };
  }

  parseStoredTemperatureData = data => {
    const heatFlux = data[0] | (data[1] << 8);
    const temperature = (data[2] | (data[3] << 8)) >>> 0;
    const coreBodyTemperature = (data[4] | (data[5] << 8)) >>> 0;
    const timestamp = (data[6] | (data[7] << 8) | (data[8] << 16) | (data[9] << 24)) >>> 0;
    return { heatFlux, temperature, coreBodyTemperature, timestamp, rawData: data };
  };

  parseStoredEcgData = data => {
    const ecg = new Array((data.length - 8) / this.ECG_BYTE_COUNT);
    for (let i = 0; i < ecg.length; i++) {
      const inc = i * this.ECG_BYTE_COUNT;
      ecg[i] = (data[0 + inc] | (data[1 + inc] << 8) | (data[2 + inc] << 16)) >>> 0;
    }
    const offset = ecg.length * this.ECG_BYTE_COUNT;
    const timestamp = (data[offset] | (data[1 + offset] << 8) | (data[2 + offset] << 16)) >>> 0;
    const leadState = data[3 + offset] & 1;
    const leadStateN = (data[3 + offset] >> 1) & 1;
    const leadStateP = (data[3 + offset] >> 2) & 1;
    const ecgQuality = data[3 + offset] >>> 3;
    const storedTimestamp =
      (data[4 + offset] | (data[5 + offset] << 8) | (data[6 + offset] << 16) | (data[7 + offset] << 24)) >>> 0;

    return { timestamp, storedTimestamp, ecg, leadState, leadStateN, leadStateP, ecgQuality, rawData: data };
  };
} // end of Parser430

class Parser440 extends Parser430 {
  constructor() {
    super();
    this.TEMPERATURE_RAW_BYTE_TOTAL = 18;
  }

  parseInformationCharacteristic(data) {
    const result = super.parseInformationCharacteristic(data);
    result.firmwareVersion.patch = data[2] >>> 0;
    result.firmwareVersion.build = data[3] >>> 0;
    result.podState = data[20] >>> 0;
    return result;
  }

  parseTemperatureData(data) {
    const heatFlux = data[0] | (data[1] << 8);
    const temperature = (data[2] | (data[3] << 8)) >>> 0;
    const coreBodyTemperature = (data[4] | (data[5] << 8)) >>> 0;
    let byteArray = Uint8Array.from(data.slice(6, 10));
    let dataView = new DataView(byteArray.buffer);
    const transformedHeatFlux = dataView.getFloat32(0, true);
    byteArray = Uint8Array.from(data.slice(10, 14));
    dataView = new DataView(byteArray.buffer);
    const transformedTemperature = dataView.getFloat32(0, true);
    const timestamp = (data[14] | (data[15] << 8) | (data[16] << 16) | (data[17] << 24)) >>> 0;
    return {
      heatFlux,
      temperature,
      coreBodyTemperature,
      transformedHeatFlux,
      transformedTemperature,
      timestamp,
      rawData: data,
    };
  }
}

class Parser600 extends Parser440 {
  parseInformationCharacteristic(data) {
    const result = super.parseInformationCharacteristic(data);
    const ch1N = data[7] & 0x01;
    const ch1P = (data[7] & 0x02) >>> 1;
    const ch2N = (data[7] & 0x04) >>> 2;
    const ch2P = (data[7] & 0x08) >>> 3;
    const ch3N = (data[7] & 0x10) >>> 4;
    const ch3P = (data[7] & 0x20) >>> 5;
    const ecgLeadState = ch1N || ch1P || ch2N || ch2P || ch3N || ch3P;
    result.ecgLeadState = Boolean(ecgLeadState);
    result.ch1N = ch1N;
    result.ch1P = ch1P;
    result.ch2N = ch2N;
    result.ch2P = ch2P;
    result.ch3N = ch3N;
    result.ch3P = ch3P;
    const podLockStatus = data[20] & 0x03;
    const podLocked = podLockStatus === 0x00;
    const podUnlockInProgress = podLockStatus === 0x01;
    const podUnlocked = podLockStatus === 0x02;
    const podMode = (data[20] & 0xfc) >>> 2;
    const normalFunction = podMode === 0x00;
    const temperatureProtection = podMode === 0x10;
    result.podLocked = podLocked;
    result.podUnlockInProgress = podUnlockInProgress;
    result.podUnlocked = podUnlocked;
    result.normalFunction = normalFunction;
    result.temperatureProtection = temperatureProtection;
    return result;
  }
} // end of Parser600

class Parser630 extends Parser600 {
  parseStoredMetricsData(data) {
    return super.parseStoredMetricsData(data, true);
  }
}

class Parser711 extends Parser630 {
  constructor() {
    super();
    this.ACC_BYTE_TOTAL = 75;
    this.ACC_COUNTER_MAX_VALUE = 16777215;
  }

  parseAccelerometerData(data) {
    return super.parseAccelerometerData(data, true);
  }
}

class Parser720 extends Parser711 {
  constructor() {
    super();
    this.TEMPERATURE_RAW_BYTE_TOTAL = 19;
  }

  parseTemperatureData(data) {
    const result = super.parseTemperatureData(data);
    const cbtStatus = data[18];
    return { cbtStatus, ...result };
  }
}

export default class ParserFactory {
  static extractFirmWare = (data, serviceID) => {
    if (data) {
      if (HEAT_SERVICE_UUID.toUpperCase() === serviceID.toUpperCase()) {
        return {
          major: data[1] >>> 0,
          minor: data[2] >>> 0,
          patch: data[3] >>> 0,
          build: data[4] >>> 0,
        };
      }
      if (SKIIN_SERVICE_UUID.toUpperCase() === serviceID.toUpperCase()) {
        const major = data[0] >>> 0;
        const minor = data[1] >>> 0;
        if (major < 4 || (major === 4 && minor < 4)) {
          return {
            major,
            minor,
            patch: (data[2] | (data[3] << 8)) >>> 0,
          };
        }
        return {
          major,
          minor,
          patch: data[2] >>> 0,
          build: data[3] >>> 0,
        };
      }
    }
    return null;
  };

  static getInstance(FWVersion, deviceUUID) {
    let major;
    let minor;
    let patch;
    if (typeof FWVersion === 'object') {
      major = FWVersion.major;
      minor = FWVersion.minor;
      patch = FWVersion.patch;
    } else if (typeof FWVersion === 'string') {
      const version = FWVersion.split('.');
      major = parseInt(version[0], 10);
      minor = parseInt(version[1], 10);
      patch = parseInt(version[2], 10);
    } else {
      return null;
    }
    if (deviceUUID.toUpperCase() === SKIIN_SERVICE_UUID.toUpperCase()) {
      return ParserFactory.getSKIINInstance(major, minor, patch);
    }
    if (deviceUUID.toUpperCase() === HEAT_SERVICE_UUID.toUpperCase()) {
      return ParserFactory.getHEATInstance(major, minor, patch);
    }
    return null;
  }

  static getHEATInstance(major, minor, patch) {
    switch (major) {
      case 1:
        switch (minor) {
          case 7:
            switch (patch) {
              case 0:
                return new HeatParser();
              default:
                return new HeatParser();
            }
          default:
            return new HeatParser();
        }
      case 2:
        switch (minor) {
          case 1:
            return new HeatParser210();
          case 2:
            return new HeatParser220();
          case 3:
            return new HeatParser220();
          default:
            return new HeatParser220();
        }
      case 3:
        switch (minor) {
          case 0:
            return new HeatParser220();
          default:
            return new HeatParser220();
        }
      case 4:
        switch (minor) {
          case 0:
            return new HeatParser400();
          case 1:
            return new HeatParser411();
          default:
            return new HeatParser411();
        }
      default:
        return new HeatParser411();
    }
  }

  static getSKIINInstance(major, minor, patch) {
    switch (major) {
      case 3:
        switch (minor) {
          case 0:
            switch (patch) {
              case 5:
                return 'non-compatibale';
              default:
                return new Parser();
            }
          case 2:
            switch (patch) {
              case 0:
                return new Parser();
              default:
                return new Parser321();
            }
          default:
            return 'non-compatibale';
        }
      case 4:
        switch (minor) {
          case 0:
          case 1:
            return new Parser400();
          case 2:
            return new Parser420();
          case 3:
            return new Parser430();
          case 4:
            return new Parser440();
          default:
            return new Parser440();
        }
      case 5:
        return new Parser600();
      case 6:
        switch (minor) {
          case 0:
          case 1:
          case 2:
            return new Parser600();
          default:
            return new Parser630();
        }
      case 7:
        switch (minor) {
          case 1:
            return new Parser711();
          case 2:
            return new Parser720();
          default:
            return new Parser720();
        }
      default:
        return new Parser720();
    }
  }
}
